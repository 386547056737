/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { App, Button, Checkbox, DatePicker, Form, Input, Select } from 'antd'
import AskDiOnlyCodeModal from 'components/AskDiOnlyCodeModal'
import { Preset, useGlobalModal } from 'components/GlobalModal'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { debounce, throttle } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AppContext } from '../../components/AppContext'
import CustomSelect from '../../components/form/Select'
import Header from '../../components/Header'
import PageContainer from '../../components/PageContainer'
import {
  GetCompnayInfoBcncResponse,
  SupplyInfoManageListDto,
} from '../../models/interfaces'
import {
  deleteReportStar,
  getCompanyInfoBcnc,
  getReportStars,
  getSupplyInfoManages,
  getSupplyInfoUdidiProduct,
  postSupplyInfoManages,
} from '../../service/repository'
import {
  getInitialForm,
  initialSupplyInforManageResponse,
  ReportForm,
  suplyFlags,
  suplyTypes,
} from './components/data'
import ExtraInput from './components/ExtraInput'
import ScanInput from './components/ScanInput'
import {
  closeCamera,
  extractRawStdCode,
  parseScanData,
  splitByAICode,
} from './components/scanUtils'
import SupplyList from './components/SupplyList'

interface Props {}

const ReportRegisterPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const { showGlobalModal } = useGlobalModal()
  const { user } = useContext(AppContext)
  const navigate = useNavigate()

  const [offset, setOffset] = useState(1)
  const [supplyInfoManagesResponse, setSupplyInfoManagesResponse] =
    useState<SupplyInfoManageListDto>(initialSupplyInforManageResponse)
  const { items: supplyInfoManages } = supplyInfoManagesResponse
  const [isShowValidation, setIsShowValidation] = useState(false)
  const [getCompanyInfoBcncResponse, setGetCompanyInfoBcncResponse] =
    useState<GetCompnayInfoBcncResponse>({
      page: 0,
      pageSize: 0,
      totalElements: 0,
      items: [],
    })
  const { items: companies } = getCompanyInfoBcncResponse
  const [scannedRawStdCode, setScannedRawStdCode] = useState('') // This is for temporal save
  const [lastedScannedRawCodes, setLastedScannedRawCodes] = useState<string>('')
  const [step, setStep] = useState(1)
  const [suplyContStdmtDate, setSuplyContStdmtDate] = useState<Date>(new Date())
  const [isScanCodeLoading, setIsScanCodeLoading] = useState(false)
  const [opendiModal, setOpendiModal] = useState<boolean>(false)

  const initialForm = getInitialForm(`${format(suplyContStdmtDate, 'yyyyMM')}`)

  const [form, setForm] = useState<ReportForm>(initialForm)
  const renderCount = useRef(0)

  const currentCameraName: 'scandit' | 'vision-camera' =
    (process.env.REACT_APP_CAMERA_NAME as 'scandit' | 'vision-camera') ??
    'scandit'

  useEffect(() => {
    const throttledScanEvent = throttle(handleScanEvent, 1000)
    window.addEventListener('message', throttledScanEvent)

    return () => {
      window.removeEventListener('message', throttledScanEvent)
      throttledScanEvent.cancel()
    }
  }, [lastedScannedRawCodes])

  const handleScanEvent = async ({ data }: any) => {
    if (typeof data !== 'string') return

    const response = parseScanData(data)
    if (!response || response.result.codes.length === 0) return

    setLastedScannedRawCodes(
      response.result.codes.map((c: any) => c.value).join(', '),
    )

    console.log(`scan response : `, response)
    await Promise.all(
      response.result.codes.map(async (code: any) => handleInput(code.value)),
    )
  }

  const sanitizeStdCode = (rawStdCode: string) => {
    // eslint-disable-next-line no-control-regex
    return rawStdCode.replace(/\uFFFD|\x1D/g, '').replace(/\]C1/g, '')
  }

  const handleInput = async (code: string) => {
    const rawStdCode = extractRawStdCode([{ value: code }]) // originally response.result.codes
    console.log('rawStdCode', rawStdCode)

    const sanitizedStdCode = sanitizeStdCode(rawStdCode)
    console.log('sanitizedStdCode', sanitizedStdCode)

    const hasAiPattern = /\(01\)|\(10\)|\(11\)|\(17\)|\(21\)/g.test(
      sanitizedStdCode,
    )

    if (hasAiPattern) {
      return await handleGS1Format(rawStdCode, sanitizedStdCode)
    } else {
      return await handleNonGS1Format(rawStdCode, sanitizedStdCode)
    }
  }

  const useDiOnly = async (sanitizedStdCode: string, rawStdCode: string) => {
    if (renderCount.current > 3 && scannedRawStdCode == sanitizedStdCode) {
      renderCount.current = 0
      closeCamera()
      setOpendiModal(true)
    } else {
      if (sanitizedStdCode == scannedRawStdCode) {
        renderCount.current += 1
      } else {
        renderCount.current = 0
      }
      setScannedRawStdCode(rawStdCode)
    }
  }

  const updateFormData = async (bracketedStdCode: string) => {
    setIsScanCodeLoading(true)
    try {
      // 괄호로 감싸진 AI 코드 사이의 UDI-DI 코드 추출
      let udiDiCode = bracketedStdCode.match(/\(01\)(.*?)\(/)?.[1] ?? ''

      // DI Only 코드인 경우 괄호로 감싸진 부분이 아닌 괄호 뒤의 부분을 udiDiCode로 처리
      if (!udiDiCode && bracketedStdCode.length <= 18) {
        udiDiCode = bracketedStdCode.match(/\(01\)(.*)/)?.[1] ?? ''
      }

      if (!udiDiCode) {
        setIsScanCodeLoading(false)
        return '인식에 실패했습니다. 입력값을 다시 확인해주세요.'
      }

      // AI 코드가 10이나 21인 경우 AI 코드로부터 다음 괄호까지 혹은 문자열 끝까지 추출
      const lotNo = bracketedStdCode.match(/\(10\)(.*?)(?=\(|$)/)?.[1] ?? ''
      const itemSeq = bracketedStdCode.match(/\(21\)(.*?)(?=\(|$)/)?.[1] ?? ''
      // AI 코드가 11이나 17인 경우 AI 코드로부터 6자리 문자열 추출
      const manufYm = bracketedStdCode.match(/\(11\)(.{6})/)?.[1] ?? ''
      const useTmlmt = bracketedStdCode.match(/\(17\)(.{6})/)?.[1] ?? ''

      console.log(
        `lotNo: ${lotNo}, itemSeq: ${itemSeq}, manufYm: ${manufYm}, useTmlmt: ${useTmlmt}`,
      )

      const udid = await fetchSupplyInfo(udiDiCode, user.id)

      if (udid) {
        if (udid.items.length == 0) {
          // 인식가능한 코드이지만 코드에 매칭되는 정보가 없는 case
          message.error('입력값에 해당하는 항목이 존재하지 않습니다.')
        } else {
          const localForm = JSON.parse(localStorage.getItem('form') || '{}')

          const suplyUntpc =
            localForm.suplyTypeCode === '2' ? udid.items[0].mxUnprc ?? 0 : 0

          const suplyQty =
            localForm.suplyTypeCode === '2'
              ? udid.items[0].packQuantity ?? localForm.suplyQty
              : localForm.suplyQty

          setForm({
            ...localForm,
            stdCode: bracketedStdCode,
            udiDiSeq: udid.items[0].udiDiSeq,
            meddevItemSeq: udid.items[0].meddevItemSeq,
            seq: udid.items[0].seq,
            udiPiCode: bracketedStdCode.slice(18) ?? '',
            udiDiCode: udid.items[0].udiDiCode,
            suplyUntpc,
            suplyAmt:
              localForm.suplyTypeCode === '2'
                ? (Number(suplyQty) * Number(suplyUntpc)).toString()
                : localForm.suplyAmt,
            lotNo,
            itemSeq,
            manufYm,
            useTmlmt,
            itemName: udid.items[0].itemName,
            suplyQty: Number(udid.items[0].packQuantity).toString() ?? '1',
          })
        }
      }
    } catch (error: any) {
      console.error('Error in updateFormData:', error)
      message.error('처리 중 오류가 발생했습니다. 다시 시도해주세요.')
    } finally {
      closeCamera()
      renderCount.current = 0
      setIsScanCodeLoading(false)
      setScannedRawStdCode('')
    }
  }
  const debouncedUpdateFormData = useMemo(
    () => debounce(updateFormData, 1000),
    [updateFormData],
  )

  const handleGS1Format = async (
    rawStdCode: string,
    sanitizedStdCode: string,
  ) => {
    if (sanitizedStdCode.length > 18) {
      return await debouncedUpdateFormData(sanitizedStdCode)
    } else {
      await useDiOnly(sanitizedStdCode, rawStdCode)
    }
    return
  }

  const handleNonGS1Format = async (
    rawStdCode: string,
    sanitizedStdCode: string,
  ) => {
    console.log(
      'charCodeAt',
      rawStdCode.split('').map((c) => `${c.charCodeAt(0)} - ${c}`),
    )

    let bracketedStdCode = ''

    if (sanitizedStdCode.startsWith('01') && sanitizedStdCode.length > 16) {
      // \u001d 문자는 가변 길이 데이터 구분자로 사용
      const splitedStdCodeBySpecialChar = rawStdCode.split('\u001d')
      const splitedStdCode = splitedStdCodeBySpecialChar.reduce((acc, code) => {
        return [...acc, ...splitByAICode(code)]
      }, [] as string[])

      // 배열 요소 각 문자열의 앞 두 글자를 괄호로 감쌈
      bracketedStdCode = splitedStdCode
        .map((code) => `(${code.substring(0, 2)})${code.substring(2)}`)
        .join('')
    } else if (
      sanitizedStdCode.startsWith('01') &&
      sanitizedStdCode.length <= 16
    ) {
      return await useDiOnly(sanitizedStdCode, rawStdCode)
    } else if (!sanitizedStdCode.startsWith('01') && scannedRawStdCode) {
      // This is for DI+PI seperated situation.
      const fullRawStdCode = scannedRawStdCode + rawStdCode

      // \u001d 문자는 가변 길이 데이터 구분자로 사용
      const splitedStdCodeBySpecialChar = fullRawStdCode.split('\u001d')
      const splitedStdCode = splitedStdCodeBySpecialChar.reduce((acc, code) => {
        return [...acc, ...splitByAICode(code)]
      }, [] as string[])

      // 배열 요소 각 문자열의 앞 두 글자를 괄호로 감쌈
      bracketedStdCode = splitedStdCode
        .map((code) => `(${code.substring(0, 2)})${code.substring(2)}`)
        .join('')
    } else {
      return '인식할 수 없는 형식의 코드입니다.'
    }

    return await debouncedUpdateFormData(bracketedStdCode)
  }

  const openCamera = (typed: 'scandit' | 'vision-camera') => {
    renderCount.current = 0
    localStorage.setItem('form', JSON.stringify(form))
    window.ReactNativeWebView.postMessage(
      btoa(
        encodeURIComponent(
          JSON.stringify({
            name: 'launchCodeScanner',
            params: {
              type: typed,
            },
          }),
        ),
      ),
    )
  }

  const fetchSupplyInfo = async (udiDiCode: string, userId: string) => {
    try {
      const { data, error } = await getSupplyInfoUdidiProduct(userId, {
        udiDiCode,
      })

      if (error) {
        const errorResponse = await error.context.json()

        if (errorResponse?.code === 'UdiSystemTokenBadRequest') {
          showGlobalModal({
            ...Preset.ErrorCode['UdiSystemTokenBadRequest'],
            onOk: () => {
              navigate('/setting/profile')
            },
          })
          return
        }

        if (errorResponse?.fromUdiSystem?.message) {
          throw errorResponse.fromUdiSystem.message
        }

        throw error.message
      }

      return data
    } catch (error: any) {
      message.error('스캔 실패: ' + error.message)
      return null
    }
  }

  const loadCompanyInfoBcnc = useCallback(async (value: string) => {
    const { data, error } = await getCompanyInfoBcnc(user.id, {
      companyName: value,
    })

    if (error) {
      const errorResponse = await error.context.json()

      if (errorResponse?.code === 'UdiSystemTokenBadRequest') {
        showGlobalModal({
          ...Preset.ErrorCode['UdiSystemTokenBadRequest'],
          onOk: () => {
            navigate('/setting/profile')
          },
        })
        return
      }

      if (errorResponse?.fromUdiSystem?.message) {
        message.error('Failed: ' + errorResponse.fromUdiSystem.message)
        return
      }

      message.error('Failed: ' + error.message)
      return
    }

    setGetCompanyInfoBcncResponse(data)
  }, [])

  const debounceFetcher = useMemo(() => {
    return debounce(loadCompanyInfoBcnc, 500)
  }, [])

  const loadSupplyInfoManages = async (offset: number) => {
    const { data, error } = await getSupplyInfoManages(user.id, {
      suplyContStdmt: `${format(suplyContStdmtDate, 'yyyyMM')}`,
      offset,
    })

    if (error) {
      const errorResponse = await error.context.json()

      if (errorResponse?.code === 'UdiSystemTokenBadRequest') {
        showGlobalModal({
          ...Preset.ErrorCode['UdiSystemTokenBadRequest'],
          onOk: () => {
            navigate('/setting/profile')
          },
        })
        return
      }

      if (errorResponse?.fromUdiSystem?.message) {
        message.error('Failed: ' + errorResponse.fromUdiSystem.message)
        return
      }

      message.error('Failed: ' + error.message)
      return
    }
    setOffset(offset)
    if (offset === 1) {
      setSupplyInfoManagesResponse(data)
    } else {
      setSupplyInfoManagesResponse({
        ...data,
        items: [...supplyInfoManages, ...data.items],
      })
    }
  }

  const [reportStars, setReportStars] = useState<any[]>([])

  const loadReportStars = async () => {
    const { data, error } = await getReportStars()
    if (error) {
      message.error('Failed: ' + error.message)
      return
    }
    setReportStars(data)
  }

  useEffect(() => {
    loadReportStars()
  }, [])

  return (
    <StyledDiv>
      <Header
        onClickBackButton={() => navigate('/')}
        title="보고자료 등록"
        companyName={user.companyName}
      />

      {opendiModal && (
        <AskDiOnlyCodeModal
          onCancel={() => {
            openCamera(currentCameraName)
            setOpendiModal(false)
            renderCount.current = -10
          }}
          onAccept={async () => {
            setOpendiModal(false)
            await updateFormData(
              `(${scannedRawStdCode.substring(0, 2)})${scannedRawStdCode.substring(2)}`,
            )
            renderCount.current = 0
          }}
        />
      )}

      <PageContainer>
        <div>
          <StyledDateContainer>
            공급기준 월
            <div>
              <DatePicker
                onChange={(date) => {
                  if (!date) return
                  setSuplyContStdmtDate(date.toDate())
                }}
                picker="month"
                value={suplyContStdmtDate ? dayjs(suplyContStdmtDate) : null}
                style={{ margin: '0 auto' }}
              />
            </div>
            <Button
              onClick={() => {
                loadSupplyInfoManages(1)
                setStep(2)
              }}
            >
              조회
            </Button>
          </StyledDateContainer>
        </div>

        {step === 1 && (
          <>
            <StyledCardContainer>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/reports/register/new')
                }}
              >
                즐겨찾기 추가
              </Button>
            </StyledCardContainer>
            <GridContainer style={{ marginTop: 16 }}>
              {reportStars.map((reportStar) => (
                <StyledCard key={`${reportStar.id}-${reportStar.bcncCode}`}>
                  <StyledCardTitle>{reportStar.title}</StyledCardTitle>
                  <StyledCardContent>
                    <StyledCardContentTitle>출고 일자</StyledCardContentTitle>
                    <StyledCardContentText>오늘</StyledCardContentText>
                  </StyledCardContent>
                  <StyledCardContent>
                    <StyledCardContentTitle>공급 구분</StyledCardContentTitle>
                    <StyledCardContentText>
                      {
                        suplyFlags.find(
                          (suplyFlag) =>
                            suplyFlag.value === reportStar.suplyFlagCode,
                        )?.label
                      }
                    </StyledCardContentText>
                  </StyledCardContent>
                  <StyledCardContent>
                    <StyledCardContentTitle>공급 형태</StyledCardContentTitle>
                    <StyledCardContentText>
                      {
                        suplyTypes.find(
                          (suplyType) =>
                            suplyType.value === reportStar.suplyTypeCode,
                        )?.label
                      }
                    </StyledCardContentText>
                  </StyledCardContent>
                  <StyledCardContent>
                    <StyledCardContentTitle>납품 장소</StyledCardContentTitle>
                    <StyledCardContentText>
                      {reportStar.dvyfgPlaceBcncName ||
                        reportStar.dvyfgPlaceBcncCode ||
                        '동일'}
                    </StyledCardContentText>
                  </StyledCardContent>
                  <StyledButtonContainer style={{ marginTop: 16 }}>
                    <Button
                      onClick={async () => {
                        if (!window.confirm('정말로 삭제하시겠습니까?')) {
                          return
                        }
                        await deleteReportStar(user.id, reportStar.id)
                        message.success('삭제되었습니다.')
                        loadReportStars()
                      }}
                    >
                      삭제
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: 8 }}
                      onClick={() => {
                        localStorage.removeItem('form')
                        loadSupplyInfoManages(1)
                        setForm((prevForm) => ({
                          ...prevForm,
                          suplyFlagCode: reportStar.suplyFlagCode,
                          suplyTypeCode: reportStar.suplyTypeCode,
                          bcncCode: reportStar.bcncCode,
                          dvyfgPlaceBcncCode: reportStar.dvyfgPlaceBcncCode,
                          isDiffDvyfg: reportStar.isDiffDvyfg,
                          suplyDate: format(new Date(), 'yyyyMMdd'),
                        }))
                        setStep(2)
                        setTimeout(() => {
                          Promise.all([
                            reportStar.title
                              ? loadCompanyInfoBcnc(
                                  reportStar.title.split(' (')[0],
                                )
                              : null,
                            reportStar.dvyfgPlaceBcncName
                              ? loadCompanyInfoBcnc(
                                  reportStar.dvyfgPlaceBcncName.split(' (')[0],
                                )
                              : null,
                          ])
                        }, 0)
                      }}
                    >
                      사용
                    </Button>
                  </StyledButtonContainer>
                </StyledCard>
              ))}
            </GridContainer>
          </>
        )}

        {step === 2 && (
          <StyledCardContainer>
            <StyledCard>
              <StyledCardTitle>보고자료 등록</StyledCardTitle>
              <Form layout="vertical">
                <CustomSelect
                  valueList={suplyFlags}
                  label="공급 구분"
                  value={form.suplyFlagCode}
                  validateStatus={
                    isShowValidation && !form.suplyFlagCode
                      ? 'error'
                      : 'success'
                  }
                  onClick={(value) => {
                    setForm({
                      ...form,
                      suplyFlagCode: value,
                    })
                  }}
                />
                <CustomSelect
                  valueList={suplyTypes}
                  value={form.suplyTypeCode}
                  validateStatus={
                    isShowValidation &&
                    (form.suplyFlagCode === '1' ||
                      form.suplyFlagCode === '4') &&
                    !form.suplyTypeCode
                      ? 'error'
                      : 'success'
                  }
                  label="공급 형태"
                  onClick={(value) => {
                    console.log('콘솔 ', value)
                    setForm({
                      ...form,
                      suplyTypeCode: value,
                    })
                  }}
                />
                <StyledFormItem
                  validateStatus={
                    isShowValidation &&
                    (form.suplyFlagCode === '1' ||
                      form.suplyFlagCode === '2') &&
                    !form.bcncCode
                      ? 'error'
                      : 'success'
                  }
                  label="업체(기관)명"
                >
                  <Select
                    showSearch
                    value={form.bcncCode}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    onSearch={debounceFetcher}
                    onChange={(value) => {
                      setForm({ ...form, bcncCode: value })
                    }}
                    notFoundContent={null}
                    options={companies.map((d) => ({
                      value: d.bcncCode,
                      label: `${d.companyName} (${d.bossName})`,
                    }))}
                  />
                </StyledFormItem>

                <StyledCheckboxContainer>
                  <div>납품업체와 장소가 다를 경우</div>
                  <Checkbox
                    checked={form.isDiffDvyfg}
                    onChange={(e) =>
                      setForm({ ...form, isDiffDvyfg: e.target.checked })
                    }
                  />
                </StyledCheckboxContainer>

                <StyledFormItem
                  validateStatus={
                    isShowValidation &&
                    form.suplyFlagCode === '1' &&
                    form.isDiffDvyfg &&
                    !form.dvyfgPlaceBcncCode
                      ? 'error'
                      : 'success'
                  }
                  label="납품장소"
                >
                  <Select
                    disabled={!form.isDiffDvyfg}
                    showSearch
                    value={form.dvyfgPlaceBcncCode}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    onSearch={debounceFetcher}
                    onChange={(value) =>
                      setForm({ ...form, dvyfgPlaceBcncCode: value })
                    }
                    notFoundContent={null}
                    options={companies.map((d) => ({
                      value: d.bcncCode,
                      label: `${d.companyName} (${d.bossName})`,
                    }))}
                  />
                </StyledFormItem>

                <StyledFormItem
                  validateStatus={
                    isShowValidation && !form.suplyDate ? 'error' : 'success'
                  }
                  label="일자"
                >
                  <DatePicker
                    inputReadOnly
                    style={{ width: '100%' }}
                    value={form.suplyDate ? dayjs(form.suplyDate) : null}
                    allowClear={false}
                    onChange={(date) => {
                      setForm({
                        ...form,
                        suplyDate: format(date!.toDate(), 'yyyyMMdd'),
                      })
                    }}
                  />
                </StyledFormItem>

                <StyledFormItem
                  validateStatus={
                    isShowValidation &&
                    form.suplyFlagCode === '5' &&
                    !form.suplyQty &&
                    !form.indvdlzSuplyQty
                      ? 'error'
                      : 'success'
                  }
                  label="수량"
                >
                  <Input
                    value={form.suplyQty}
                    type="phone"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        suplyQty: e.target.value,
                        suplyAmt:
                          form.suplyTypeCode === '2'
                            ? (
                                Number(e.target.value) * Number(form.suplyUntpc)
                              ).toString()
                            : form.suplyAmt,
                      })
                    }
                  />
                </StyledFormItem>
                <ScanInput
                  isShowValidation={isShowValidation}
                  form={form}
                  setForm={setForm}
                  isScanCodeLoading={isScanCodeLoading}
                  openCamera={openCamera}
                  handleInput={handleInput}
                />
                <ExtraInput
                  isShowValidation={isShowValidation}
                  form={form}
                  setForm={setForm}
                />
              </Form>

              <StyledButtonContainer>
                <Button
                  type="primary"
                  onClick={async () => {
                    setIsShowValidation(true)
                    if (
                      !form.suplyFlagCode ||
                      ((form.suplyFlagCode === '1' ||
                        form.suplyFlagCode === '4') &&
                        !form.suplyTypeCode) ||
                      ((form.suplyFlagCode === '1' ||
                        form.suplyFlagCode === '2') &&
                        !form.bcncCode) ||
                      (form.suplyFlagCode === '1' &&
                        form.isDiffDvyfg &&
                        !form.dvyfgPlaceBcncCode) ||
                      !form.suplyDate ||
                      (form.suplyFlagCode === '5' &&
                        !form.suplyQty &&
                        !form.indvdlzSuplyQty) ||
                      (form.suplyFlagCode === '2' && !form.suplyUntpc) ||
                      (form.suplyFlagCode === '2' && !form.suplyAmt) ||
                      !form.stdCode
                    ) {
                      return
                    }

                    const { error } = await postSupplyInfoManages(user.id, form)

                    if (error) {
                      const errorResponse = await error.context.json()

                      if (errorResponse?.code === 'UdiSystemTokenBadRequest') {
                        showGlobalModal({
                          ...Preset.ErrorCode['UdiSystemTokenBadRequest'],
                          onOk: () => {
                            navigate('/setting/profile')
                          },
                        })
                        return
                      }

                      if (errorResponse?.fromUdiSystem?.message) {
                        message.error(
                          'Failed: ' + errorResponse.fromUdiSystem.message,
                        )
                        return
                      }

                      message.error('Failed: ' + error.message)
                      return
                    }

                    message.success('보고자료등록 성공')
                    setForm({
                      ...form,
                      stdCode: '',
                      suplyUntpc: '0',
                      udiDiSeq: '',
                      meddevItemSeq: '',
                      seq: '',
                      udiPiCode: '',
                      udiDiCode: '',
                    } as any)
                    setOffset(-1)
                    setSupplyInfoManagesResponse(
                      initialSupplyInforManageResponse,
                    )
                    setIsShowValidation(false)
                    loadSupplyInfoManages(1)
                    localStorage.removeItem('form')
                  }}
                >
                  저장
                </Button>
              </StyledButtonContainer>
            </StyledCard>

            <StyledTitle>{`${format(suplyContStdmtDate, 'yyyyMM')} - ${supplyInfoManages.length}개`}</StyledTitle>

            <SupplyList
              offset={offset}
              loadSupplyInfoManages={(offset: number) =>
                loadSupplyInfoManages(offset)
              }
              supplyInfoManages={supplyInfoManages}
              hasMore={
                supplyInfoManagesResponse.totalElements >
                supplyInfoManages.length
              }
            />
          </StyledCardContainer>
        )}
      </PageContainer>
    </StyledDiv>
  )
}

const StyledCardContainer = styled.div`
  margin-top: 16px;
`

const StyledCard = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 16px;
`

const StyledCardTitle = styled.div`
  margin-bottom: 16px;
  font-weight: bold;
`

const StyledButtonContainer = styled.div`
  text-align: right;
`

const StyledTitle = styled.div`
  margin-top: 16px;
  font-weight: bold;
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`

const StyledDiv = styled.div`
  overflow: scroll;
`

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-picker {
    margin: 0 8px !important;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 4px;
`

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const StyledCardContentTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  flex-shrink: 0;
`

const StyledCardContentText = styled.div`
  font-size: 12px;
`

export default ReportRegisterPage
