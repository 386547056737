import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { createContext, ReactNode, useContext, useState } from 'react'

interface GlobalModalProps {
  content: ReactNode
  title: ReactNode
  cancelText: string | null
  okText: string | null
  onOk?: () => void
}

type ModalContextType = {
  showGlobalModal: (props: GlobalModalProps) => void
  hideGlobalModal: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const useGlobalModal = (): ModalContextType => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useGlobalModal must be used within a ModalProvider')
  }
  return context
}

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactNode>(null)
  const [title, setTitle] = useState<ReactNode>(null)
  const [cancelText, setCancelText] = useState<string | null>(null)
  const [okText, setOkText] = useState<string | null>(null)
  const [onOk, setOnOk] = useState<VoidFunction | undefined>(undefined)

  const showGlobalModal = ({
    content,
    title,
    cancelText,
    okText,
    onOk,
  }: GlobalModalProps) => {
    setContent(content)
    setTitle(title)
    setCancelText(cancelText)
    setOkText(okText)
    setOnOk(() => onOk)

    setIsOpen(true)
  }

  const handleCancel = () => {
    setContent(null)
    setTitle(null)
    setCancelText(null)
    setOkText(null)
    setOnOk(undefined)

    setIsOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{ showGlobalModal, hideGlobalModal: handleCancel }}
    >
      {children}
      <Modal
        title={title}
        open={isOpen}
        cancelText={cancelText}
        okText={okText}
        onCancel={handleCancel}
        onOk={() => {
          onOk?.()
          handleCancel()
        }}
      >
        {content}
      </Modal>
    </ModalContext.Provider>
  )
}

export const Preset = {
  ErrorCode: {
    UdiSystemTokenBadRequest: {
      title: (
        <span>
          <ExclamationCircleFilled
            style={{ color: '#faad14', marginRight: '8px' }}
          />
          인증 오류
        </span>
      ),
      content: (
        <p>
          판매 인증 ID 혹은 PW가 올바르지 않습니다. [정보 수정]으로 이동하여
          올바른 값으로 변경해주세요.
        </p>
      ),
      cancelText: '닫기',
      okText: '정보 수정으로 이동',
    },
  },
}
