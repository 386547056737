import { App, Button, Form, Input, List, Modal, Select } from 'antd'
import { Option } from 'antd/es/mentions'
import { Preset, useGlobalModal } from 'components/GlobalModal'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AppContext } from '../components/AppContext'
import Header from '../components/Header'
import PageContainer from '../components/PageContainer'
import StyledButton from '../components/StyledButton'
import {
  GetCompnayInfoBcnc,
  GetCompnayInfoBcncResponse,
} from '../models/interfaces'
import { deleteCompanyInfoBcnc } from '../service/repository'
import supabase, { productionClientKey } from '../service/supabase'

interface Props {}

interface Form {
  companyName?: string
  taxNo?: string
  cobFlagCode?: number
  bossName?: string
  area?: number
}

const CompnayPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const { showGlobalModal } = useGlobalModal()
  const { user } = useContext(AppContext)
  const navigate = useNavigate()

  const [offset, setOffset] = useState(1)
  const [getCompanyInfoBcncResponse, setGetCompanyInfoBcncResponse] =
    useState<GetCompnayInfoBcncResponse>({
      page: 0,
      pageSize: 0,
      totalElements: 0,
      items: [],
    })
  const { items: companies } = getCompanyInfoBcncResponse
  const [form, setForm] = useState<Form>({})
  const [deletingCompany, setDeletingCompany] =
    useState<GetCompnayInfoBcnc | null>(null)

  const cobFlags = [
    {
      label: '제조',
      value: '1',
    },
    {
      label: '수입',
      value: '2',
    },
    {
      label: '수리',
      value: '3',
    },
    {
      label: '판매(임대)',
      value: '9',
    },
    {
      label: '요양기관',
      value: '10',
    },
    {
      label: '기타',
      value: '99',
    },
  ]

  const areas = [
    {
      label: '서울',
      value: '1',
    },
    {
      label: '경기',
      value: '2',
    },
    {
      label: '강원',
      value: '3',
    },
    {
      label: '충청북도',
      value: '4',
    },
    {
      label: '충청남도',
      value: '5',
    },
    {
      label: '인천',
      value: '6',
    },
    {
      label: '세종',
      value: '7',
    },
    {
      label: '대전',
      value: '8',
    },
    {
      label: '광주',
      value: '9',
    },
    {
      label: '전라남도',
      value: '10',
    },
    {
      label: '전라북도',
      value: '11',
    },
    {
      label: '경상북도',
      value: '12',
    },
    {
      label: '경상남도',
      value: '13',
    },
    {
      label: '대구',
      value: '14',
    },
    {
      label: '울산',
      value: '15',
    },
    {
      label: '부산',
      value: '16',
    },
    {
      label: '제주',
      value: '17',
    },
  ]

  const loadSupplyInfoManages = async (offset: number) => {
    const { data, error } = await supabase.functions.invoke(
      'getCompanyInfoBcnc',
      {
        headers: {
          ...(window.__APP_DEV__ === 'production'
            ? { ClientKey: productionClientKey }
            : {}),
        },
        body: {
          offset,
          ...form,
        },
      },
    )

    if (error) {
      const errorResponse = await error.context.json()

      if (errorResponse?.code === 'UdiSystemTokenBadRequest') {
        showGlobalModal({
          ...Preset.ErrorCode['UdiSystemTokenBadRequest'],
          onOk: () => {
            navigate('/setting/profile')
          },
        })
        return
      }

      if (errorResponse?.fromUdiSystem?.message) {
        message.error('Failed: ' + errorResponse.fromUdiSystem.message)
        return
      }

      message.error('Failed: ' + error.message)
      return
    }

    setOffset(offset)
    if (offset === 1) {
      setGetCompanyInfoBcncResponse(data)
    } else {
      setGetCompanyInfoBcncResponse({
        ...data,
        items: [...getCompanyInfoBcncResponse.items, ...data.items],
      })
    }
  }

  return (
    <>
      <Header
        onClickBackButton={() => navigate('/')}
        title="거래처 관리"
        companyName={user.companyName}
      />
      <PageContainer>
        <StyledCard>
          <Form layout="vertical">
            <StyledFormItem label="거래처명">
              <Input
                value={form.companyName}
                onChange={(e) =>
                  setForm({ ...form, companyName: e.target.value })
                }
              />
            </StyledFormItem>

            <StyledFormItem label="업종">
              <Select
                allowClear
                onChange={(value) => {
                  setForm({
                    ...form,
                    cobFlagCode: value,
                  })
                }}
              >
                {cobFlags.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </StyledFormItem>

            <StyledFormItem label="사업자등록번호">
              <Input
                value={form.taxNo}
                onChange={(e) => setForm({ ...form, taxNo: e.target.value })}
              />
            </StyledFormItem>

            <StyledFormItem label="대표자명">
              <Input
                value={form.bossName}
                onChange={(e) => setForm({ ...form, bossName: e.target.value })}
              />
            </StyledFormItem>

            <StyledFormItem label="지역">
              <Select
                allowClear
                onChange={(value) => {
                  setForm({
                    ...form,
                    area: value,
                  })
                }}
              >
                {areas.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </StyledFormItem>
          </Form>

          <StyledButtonContainer>
            <Button
              type="primary"
              onClick={async () => loadSupplyInfoManages(1)}
            >
              검색
            </Button>
          </StyledButtonContainer>
        </StyledCard>

        <StyledListContainer>
          <InfiniteScroll
            dataLength={companies.length}
            next={() => {
              loadSupplyInfoManages(offset + 1)
            }}
            hasMore={
              getCompanyInfoBcncResponse.totalElements > companies.length
            }
            loader={<StyledListText>Loading...</StyledListText>}
          >
            <List
              header={
                <div>
                  <b>업체명 (대표자)</b>
                  <div>사업자번호 (지역)</div>
                </div>
              }
              dataSource={companies}
              renderItem={(item) => (
                <List.Item key={item.no}>
                  <div>
                    <b>
                      {item.companyName}
                      {item.bossName ? ` (${item.bossName})` : ''}
                    </b>
                    <div>
                      {item.taxNo} ({item.area})
                    </div>
                  </div>
                  <div>
                    <Button
                      size="small"
                      onClick={async (e) => {
                        e.stopPropagation()
                        setDeletingCompany(item)
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </List.Item>
              )}
            >
              {companies.length === 0 && (
                <StyledListText>검색 조건에 맞는 거래처 없음</StyledListText>
              )}
            </List>
          </InfiniteScroll>
        </StyledListContainer>

        <StyledSubmittButton
          type="primary"
          onClick={async () => {
            navigate('/companies/register')
          }}
        >
          거래처 추가
        </StyledSubmittButton>

        <Modal
          title="삭제"
          open={!!deletingCompany}
          onOk={async () => {
            if (!deletingCompany) {
              return
            }

            const { error } = await deleteCompanyInfoBcnc(user.id, {
              bcncCode: Number(deletingCompany.bcncCode),
            })

            setDeletingCompany(null)

            if (error) {
              const errorResponse = await error.context.json()

              if (errorResponse?.code === 'UdiSystemTokenBadRequest') {
                showGlobalModal({
                  ...Preset.ErrorCode['UdiSystemTokenBadRequest'],
                  onOk: () => {
                    navigate('/setting/profile')
                  },
                })
                return
              }

              if (errorResponse?.fromUdiSystem?.message) {
                message.error('Failed: ' + errorResponse.fromUdiSystem.message)
                return
              }

              message.error('Failed: ' + error.message)
              return
            }

            message.success('삭제되었습니다.')
            loadSupplyInfoManages(1)
          }}
          onCancel={() => setDeletingCompany(null)}
        >
          거래처를 삭제하시겠습니까?
        </Modal>
      </PageContainer>
    </>
  )
}

const StyledCard = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 16px;
`

const StyledButtonContainer = styled.div`
  text-align: right;
  margin-top: 16px;
`

const StyledSubmittButton = styled(StyledButton)`
  margin-top: 16px;
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`

const StyledListText = styled.div`
  text-align: center;
  margin: 16px 0;
`

const StyledListContainer = styled.div`
  margin-top: 16px;

  & .ant-list-header {
    background-color: #f0f0f0;
    padding: 8px;
  }

  & .ant-list-item {
    padding: 8px;
  }
`

export default CompnayPage
