import { App, Button, Form } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import StyledButton from '../components/StyledButton'
import StyledInput from '../components/StyledInput'
import { getCompanyNames } from '../service/repository'
import supabase from '../service/supabase'

interface Props {}

interface Form {
  id: string
  password: string
  passwordConfirm: string
  companyNumber: string
  isCompanyNumberChecked: boolean
  companyName: string
}

const SignupPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { message } = App.useApp()

  const [form, setForm] = useState<Form>({
    id: '',
    password: '',
    passwordConfirm: '',
    companyNumber: '',
    isCompanyNumberChecked: false,
    companyName: '',
  })
  const [isLoadingCompanyNumber, setIsLoadingCompanyNumber] = useState(false)
  const [isShowValidation, setIsShowValidation] = useState(false)
  const [isInvalidPasswordConfirm, setIsInvalidPasswordConfirm] =
    React.useState(true)

  const onClickSignupButton = async () => {
    setIsShowValidation(true)
    if (
      !form.id ||
      !form.password ||
      !form.passwordConfirm ||
      isInvalidPasswordConfirm ||
      !form.companyName ||
      !form.companyNumber
    ) {
      return
    }

    const { error } = await supabase.auth.signUp({
      email: `${form.id}@user.udipass.optimwork`,
      password: form.password,
      options: {
        data: {
          bizRegNumber: form.companyNumber,
          companyName: form.companyName,
        },
      },
    })

    if (error) {
      message.error('Failed: ' + error.message)
      return
    }

    message.success('회원가입이 완료되었습니다.')
    navigate('/login')
  }

  return (
    <>
      <Header
        title="회원가입"
        onClickBackButton={() => {
          navigate('/login')
        }}
      />

      <StyledSignupPage>
        <Form layout="vertical">
          <Form.Item
            validateStatus={isShowValidation && !form.id ? 'error' : 'success'}
          >
            <StyledInput
              value={form.id}
              placeholder="아이디"
              onChange={(e) => {
                setForm({ ...form, id: e.target.value })
              }}
            />
          </Form.Item>

          <Form.Item
            validateStatus={
              isShowValidation && !form.password ? 'error' : 'success'
            }
          >
            <StyledInput
              value={form.password}
              type="password"
              placeholder="비밀번호"
              onChange={(e) => {
                const password = e.target.value
                setIsInvalidPasswordConfirm(form.passwordConfirm !== password)
                setForm({ ...form, password: e.target.value })
              }}
            />
          </Form.Item>

          <Form.Item
            validateStatus={
              isShowValidation &&
              (!form.passwordConfirm || isInvalidPasswordConfirm)
                ? 'error'
                : 'success'
            }
            help={
              isShowValidation && isInvalidPasswordConfirm
                ? '비밀번호가 일치하지 않습니다.'
                : ''
            }
          >
            <StyledInput
              value={form.passwordConfirm}
              type="password"
              placeholder="비밀번호 확인"
              onChange={(e) => {
                const passwordConfirm = e.target.value
                setIsInvalidPasswordConfirm(form.password !== passwordConfirm)
                setForm({ ...form, passwordConfirm: passwordConfirm })
              }}
            />
          </Form.Item>

          <StyledFormItem>
            <Form.Item
              validateStatus={
                isShowValidation && !form.companyNumber ? 'error' : 'success'
              }
            >
              <StyledInput
                value={form.companyNumber}
                type="phone"
                placeholder="사업자등록번호"
                onChange={(e) => {
                  setForm({
                    ...form,
                    companyNumber: e.target.value,
                    isCompanyNumberChecked: false,
                  })
                }}
              />
            </Form.Item>
            <Button
              loading={isLoadingCompanyNumber}
              onClick={async () => {
                setIsLoadingCompanyNumber(true)

                const response = await getCompanyNames({
                  bizRegNumber: form.companyNumber,
                })

                if (response.status !== 200) {
                  message.error('Failed: ' + response.statusText)
                  return
                }

                const data = await response.json()

                if (!data || !data.companyName) {
                  message.error('사업자등록번호를 조회할 수 없습니다.')
                  setForm({
                    ...form,
                    isCompanyNumberChecked: false,
                    companyName: '',
                  }) // TODO: 회사명 가져오는 API 도입 전까지 무력화
                  setIsLoadingCompanyNumber(false)
                  return
                }

                setForm({
                  ...form,
                  isCompanyNumberChecked: true,
                  companyName: '', // data.companyName, // TODO: 회사명 가져오는 API 도입 전까지 무력화
                })
                setIsLoadingCompanyNumber(false)

                message.success('사업자등록번호 조회가 완료되었습니다. 회사명을 입력해주세요.')
              }}
            >
              조회
            </Button>
          </StyledFormItem>

          {form.isCompanyNumberChecked && (
            <Form.Item
              validateStatus={
                isShowValidation && !form.companyName ? 'error' : 'success'
              }
            >
              <StyledInput
                // value={'사업자등록번호 조회 완료' /*form.companyName*/} // TODO: 회사명 가져오는 API 도입 전까지 무력화
                value={form.companyName}
                placeholder="회사명을 입력해주세요"
                onChange={(e) => {
                  setForm({ ...form, companyName: e.target.value })
                }}
              />
            </Form.Item>
          )}

          <StyledButton
            type="primary"
            block
            disabled={
              form.id === '' ||
              form.password === '' ||
              form.passwordConfirm === '' ||
              form.companyNumber === '' ||
              form.isCompanyNumberChecked === false ||
              form.companyName === ''
            }
            onClick={onClickSignupButton}
          >
            회원가입
          </StyledButton>
        </Form>
      </StyledSignupPage>
    </>
  )
}

const StyledSignupPage = styled.div`
  padding: 32px;
  margin-top: 56px;
  overflow: scroll;
  height: calc(100vh - 80px);
`

const StyledFormItem = styled.div`
  display: flex;
  flex-direction: row;

  .ant-form-item {
    flex: 1;
    margin-right: 8px;
  }

  .ant-btn {
    height: 44px;
  }
`

export default SignupPage
